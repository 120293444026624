export default function PS() {
    const OneHourse = 300;
    const threeHourse = 700;
    const foureHourse = 900;
    const night = 1000;
    return (

        <div id='price' className="configuration__tab-contents">
            <div className="configuration__tabs" >
                <button className='configuration__tab is-active'>Цены</button>
            </div>
            <div className="configuration__tab-content" >
                <div className="configuration__grid">
                    <div className="configuration__item2">
                        <div className="configuration__icon2">

                            <div className="configuration__type2">Час </div>
                        </div>
                        <div className="configuration__info">
                            <div className="configuration__type"></div>
                            <div className="configuration__name2">{OneHourse}</div>
                        </div>
                    </div>
                    <div className="configuration__item2 ">
                        <div className="configuration__icon2">
                            <div className="configuration__type2">Пакет 3 Часа</div>
                        </div>
                        <div className="configuration__info">
                            <div className="configuration__name2">{threeHourse}</div>
                        </div>
                    </div>
                    <div className="configuration__item2 ">
                        <div className="configuration__icon2">
                            <div className="configuration__type2">Пакет 4 Часа</div>
                        </div>
                        <div className="configuration__info">
                            <div className="configuration__name2">{foureHourse}</div>
                        </div>
                    </div>
                    <div className="configuration__item2 ">
                        <div className="configuration__icon2">
                            <div className="configuration__type2">Пакет ночь</div>
                        </div>
                        <div className="configuration__info">
                            <div className="configuration__name2">{night}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}