import React, { useEffect, useState } from 'react';
import '../index';
import { Header } from './Header';
import Vip from './category/Vip';
import { Lk } from './Lk';
import { PopupWithForm } from './PopupWithForm';
import About from './About';
import Standart from './category/Standart';
import Komfort from './category/Komfort';
import Komfortplus from './category/Komfortplus';
import Photo from './Photo';
import { EditProfilePopup } from './EditProfilePopup';
import Game from './Game';
import Footer from './Footer';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import { Login } from './Login';
import BottomNav from './BottomNav'
import { ProtectedRoute } from './ProtectedRoute';
import * as auth from '../utils/auth';
import { InfoTooltip } from './InfoTooltip';
import { Route, Routes, useNavigate, Navigate } from 'react-router';
import checkmarkImg from '../images/svg/success.svg';
import crossImg from '../images/svg/fail.svg';
import Zal from './Zal';
import Lenis from 'lenis';
import logo from '../images/logo.png'
import PS from './category/PS';
import AdminPanel from './Admin';
const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <img src={logo} alt="Загрузка..." className="loading-screen_img" />
      <div className="spinner" />
    </div>
  );
};

function App() {
  const [isEditProfilePopupOpen, setEditBookingPopupOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [popupStatus, setPopupStatus] = useState({ image: '', message: '' });
  const [infoTooltip, setInfoTooltip] = useState(false);
  const [balanceValue, setBalanceValue] = useState(null);
  const [coinsValue, setCoinsValue] = useState(null);
  const [userName, setUserName] = useState(null);
  const [loading, setLoading] = useState(true);
  // Объект состояния для всех ПК
  const [pcData, setPcData] = useState(
    Array.from({ length: 45 }, (_, i) => ({ id: i + 1, availableTime: null }))
  );

  // Объект состояния для всех игровых консолей
  const [consoleData, setConsoleData] = useState({
    PS4_1: { id: '', availableTime: '' },
    PS4_2: { id: '', availableTime: '' },
    PS5: { id: '', availableTime: '' },
  });
  // Smooth scroll
  const lenis = new Lenis();
  lenis.on('scroll', (e) => { });
  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }
  requestAnimationFrame(raf);
  const navigate = useNavigate();
  // Функция для получения данных о пользователе
  const fetchUserData = (userId) => {
    auth.balance({ userId })
      .then((res) => {
        setBalanceValue(res.result.balance);
        setCoinsValue(res.result.points);
      })
      .catch((error) => {
        console.error('Ошибка при получении данных пользователя:', error);
      });
  };

  const handleEditProfileClick = () => setEditBookingPopupOpen(true);

  const closeAllPopups = () => {
    setEditBookingPopupOpen(false);
    setInfoTooltip(false);
  };

  const handleUpdateUser = (data) => {
    const dateVariable = new Date().toLocaleString();
    const year = dateVariable.slice(6, 10);
    const month = dateVariable.slice(3, 5);
    const day = dateVariable.slice(0, 2);

    const numberToTG = data.pcNumber;
    // Маппинг для pcNumber
    const pcNumberMapping = {
      '1': '33', '2': '20', '3': '21', '4': '22', '5': '36', '6': '23', '7': '24', '8': '25', '9': '29',
      '10': '27', '11': '28', '12': '30', '13': '39', '14': '40', '15': '32', '16': '31', '17': '36',
      '18': '38', '19': '37', '20': '41', '21': '42', '22': '43', '23': '45', '24': '46', '25': '44',
      '26': '34', '27': '35', '28': '51', '29': '7', '30': '9', '31': '11', '32': '3', '33': '4',
      '34': '10', '35': '6', '36': '13', '37': '8', '38': '15', '39': '12', '40': '14', '41': '18',
      '42': '5', '43': '19', '44': '17', '45': '16'
    };

    if (pcNumberMapping[data.pcNumber]) {
      data.pcNumber = pcNumberMapping[data.pcNumber];
    }
    let firstPin = Math.floor(Math.random() * 10)


    var abc = "abcdefghijklmnopqrstuvwxyz";
    var rs = "";
    while (rs.length < 6) {
      rs += abc[Math.floor(Math.random() * abc.length)];
    }
    let secondPin = rs[1]
    let thrithy = rs[0]
    let four = Math.floor(Math.random() * 10)
    let five = Math.floor(Math.random() * 10)
    let six = rs[2]
    // Исправленный объект bookingData
    const bookingData = {
      userId: parseInt(localStorage.getItem('userid'), 10), // Преобразуем userId в число
      date: `${year}-${month}-${day}T${data.time}:43.089Z`,
      duration: data.pcDuration * 60,
      contactPhone: data.userNumber, // Убедитесь, что это правильный номер телефона
      contactEmail: "", // Добавляем пустую строку для email
      note: "", // Добавляем пустую строку для заметок
      pin: `${firstPin}` + `${secondPin}` + `${thrithy}` + `${four}` + `${five}` + `${six}`,
      status: 0,
      hosts: [{ hostId: data.pcNumber }],
      users: [{ userId: parseInt(localStorage.getItem('userid'), 10) }] // Преобразуем userId в число
    };

    auth.booking(bookingData)
      .then((res) => {
        handleInfoTooltip();
        setPopupStatus({ image: checkmarkImg, message: 'Вы успешно забронировали!' });

        // Формирование данных для отправки в Telegram в виде строки

        const username = localStorage.getItem('username');
        const bookingDataForTG = `Имя: ${username}\nПриду в ${data.time}\nНомер телефона: ${data.userNumber}\nНа PC: ${numberToTG}\nНа ${data.pcDuration} часа`;

        // Отправка данных в Telegram как строка
        auth.telegram(bookingDataForTG);
      })
      .catch((error) => {
        console.error('Ошибка бронирования:', error); // Логирование ошибки
        setPopupStatus({ image: crossImg, message: 'Что-то пошло не так! Попробуйте еще раз.' });
      });

    setEditBookingPopupOpen(false);
  };


  const handleLogin = (username, password) => {
    auth.authorize({ username, password })
      .then((res) => {
        localStorage.setItem('username', username);
        localStorage.setItem('jwt', res.result.token);
        setIsLoggedIn(true);
        setUserName(username);
        navigate('/');
        // Получаем userId и сразу же вызываем fetchUserData
        auth.userId({ username }).then((res) => {
          const userId = res.result.id;
          localStorage.setItem('userid', userId);
          fetchUserData(userId); // Загрузка данных пользователя после логина
        });
      })
      .catch(() => {
        setPopupStatus({ image: crossImg, message: 'Что-то пошло не так! Попробуйте еще раз.' });
        handleInfoTooltip();
        navigate('/');
      });
  };

  useEffect(() => {
    auth.availableTime().then((res) => {
      const availableTime = res.result;

      availableTime.forEach((element) => {
        let userId = element.userId;
        auth.availableTimeOnPc({ userId }).then((res) => {

          const lockPc = element.hostName;
          const pcNumber = parseInt(lockPc.match(/\d+/)[0]);

          if (lockPc.startsWith('PC-')) {
            const newPcData = [...pcData];
            newPcData[pcNumber - 1].availableTime = new Date(res.result.availableTime * 1000).toISOString().slice(11, 16);
            setPcData(newPcData);
          } else if (lockPc.startsWith('PS')) {
            const consoleKey = lockPc.replace('-', '_');
            setConsoleData((prev) => {
              // Ограничиваем данные только для консолей PS4_1, PS4_2, PS5
              const allowedConsoles = ['PS5_1', 'PS4_2', 'PS4_3'];

              if (allowedConsoles.includes(consoleKey)) {
                if (prev[consoleKey]?.availableTime === new Date(res.result.availableTime * 1000).toISOString().slice(11, 16)) {
                  return prev; // Если данные не изменились, не обновляем
                }
                return {
                  ...prev,
                  [consoleKey]: {
                    id: lockPc,
                    availableTime: new Date(res.result.availableTime * 1000).toISOString().slice(11, 16),
                  },
                };
              }

              return prev; // Если ключ консоли не в разрешенных, не обновляем данные
            });
          }
        });
      });
    });
  }, []);

  // Эффект для загрузки данных при загрузке страницы
  useEffect(() => {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      setIsLoggedIn(true);
      const userName = localStorage.getItem('username');
      setUserName(userName);
      const userId = localStorage.getItem('userid');
      if (userId) {
        // Загружаем данные пользователя при перезагрузке страницы
        fetchUserData(userId);
      }
    }

    // Устанавливаем задержку для загрузочного экрана
    setTimeout(() => {
      setLoading(false); // Отключаем загрузочный экран после 2 секунд
    }, 2000);
  }, []);


  const handleLogOut = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('jwt');
    localStorage.removeItem('userid');
    localStorage.removeItem('username');
    setUserName(null);
    setBalanceValue(null); // Сбрасываем баланс
    setCoinsValue(null);   // Сбрасываем очки
    navigate('/sign-in');
  };

  const handleInfoTooltip = () => setInfoTooltip(true);
  // Если страница еще загружается, показываем загрузочный экран
  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div className="page">
        <Header title={isLoggedIn ? 'Выйти' : 'Войти'} onClick={isLoggedIn ? handleLogOut : ''} balance={balanceValue} />
        <BottomNav />
        <Routes>
          <Route
            exact
            path="/lk"
            element={<ProtectedRoute component={Lk} isLoggedIn={isLoggedIn} title={balanceValue} coins={coinsValue} username={userName} />}
          />
          <Route
            path="/booking"
            element={<Zal  onEditProfile={handleEditProfileClick} pcData={pcData} consoleData={consoleData} />}
          />
          <Route
            path="/sign-in"
            element={<Login onLogin={handleLogin} />}
          />
          <Route
            exact
            path="*"
            element={isLoggedIn ? <Navigate to="/" /> : <Navigate to="/" />}
          />
          <Route path='/admin' element={<AdminPanel />} />
          <Route path="/" element={<About />} />
          <Route path="/game" element={<Game />} />
          <Route path="/photo" element={<Photo />} />
          <Route path="/standart" element={<Standart />} />
          <Route path="/komfort" element={<Komfort />} />
          <Route path="/komfortplus" element={<Komfortplus />} />
          <Route path="/vip" element={<Vip />} />
          <Route path="/ps" element={<PS />} />
        </Routes>
        <PopupWithForm name="йцу" onClose={closeAllPopups} buttonText={'Да'} />
        <EditProfilePopup isOpen={isEditProfilePopupOpen} onClose={closeAllPopups} onUpdateUser={handleUpdateUser} />
        <Footer />
        <InfoTooltip popupStatus={popupStatus} isOpen={infoTooltip} onClose={closeAllPopups} />
      </div>
    </CurrentUserContext.Provider>
  );
}

export default App;
