import React, { useState, useEffect } from 'react';
import { FreeNight } from './freeNight';
import WheelOfFortune from './WheelOfFortune';
import { BASE_PROXY_URL } from '../utils/auth'

export function Lk({ title, coins, username }) {
    const [promoCode, setPromoCode] = useState(''); // Для хранения промокода
    const [balance, setBalance] = useState(title); // Для хранения баланса
    const [promoMessage, setPromoMessage] = useState(''); // Для сообщений о промокоде
    const [loginTime, setLoginTime] = useState(0); // Начальное значение для loginTime (0 для анимации)
    const [targetLoginTime, setTargetLoginTime] = useState(''); // Целевое время входа
    const [errorMessage, setErrorMessage] = useState(''); // Для ошибок

    useEffect(() => {
        // Функция для получения отчета по пользователю
        const fetchUserReport = async () => {
            try {
                const userId = localStorage.getItem('userid');
                if (!userId) {
                    throw new Error('User ID не найден в localStorage');
                }

                // Отправляем запрос на сервер API, передавая userId
                const response = await fetch(`${BASE_PROXY_URL}/playtime?userId=${userId}`);

                // Проверяем тип содержимого
                const contentType = response.headers.get('content-type');
                if (!contentType || !contentType.includes('application/json')) {
                    const text = await response.text();  // Получаем ответ как текст
                    throw new Error(`Некорректный ответ сервера: ${text}`);
                }

                const data = await response.json();
                if (response.ok) {
                    // Получаем время из ответа (в формате '319h 26m' или '319h' или '26m')
                    const timeString = data.result.loginTime;
                    let hours = 0, minutes = 0;

                    // Проверяем и извлекаем часы и минуты, если они присутствуют
                    if (timeString.includes('h')) {
                        hours = parseFloat(timeString.split('h')[0]) || 0;
                    }
                    if (timeString.includes('m')) {
                        minutes = parseFloat(timeString.split('h')[1].replace('m', '')) || 0;
                    }

                    const totalTime = hours + minutes / 60; // Преобразуем всё в часы

                    // Устанавливаем целевое время входа
                    setTargetLoginTime(totalTime);

                    // // Запускаем анимацию
                    // animateLoginTime(0, totalTime);
                } else {
                    setErrorMessage(data.error || 'Ошибка при получении данных отчета.');
                }
            } catch (error) {
                console.error('Ошибка при получении отчета:', error);  // Логируем ошибку
                setErrorMessage('Ошибка при подключении к серверу.');
            }
        };

        fetchUserReport();  // Вызываем функцию для загрузки отчета
    }, []);

    // // Функция для анимации loginTime
    // const animateLoginTime = (start, end) => {
    //     let current = start;
    //     const increment = (end - start) / 100; // Шаг анимации
    //     const duration = 5000; // Продолжительность анимации в миллисекундах
    //     const frameDuration = duration / 100; // Количество кадров

    //     const timer = setInterval(() => {
    //         current += increment;
    //         if (current >= end) {
    //             clearInterval(timer); // Останавливаем анимацию, когда достигли конца
    //             setLoginTime(end.toFixed(0)); // Устанавливаем конечное значение
    //         } else {
    //             setLoginTime(current.toFixed(0)); // Обновляем текущее значение с двумя знаками после запятой
    //         }
    //     }, frameDuration);
    // };

    const applyPromoCode = async () => {
        try {
            const response = await fetch(`${BASE_PROXY_URL}/applyPromo`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, promoCode }),
            });

            const data = await response.json();

            if (response.ok) {
                setBalance(data.newBalance);
                setPromoMessage(`Промокод применен. Ваш новый баланс: ${data.newBalance}`);
            } else {
                setPromoMessage(data.error || 'Ошибка при применении промокода.');
            }
        } catch (error) {
            console.error('Ошибка при применении промокода:', error);
            setPromoMessage('Ошибка при подключении к серверу.');
        }
    };

    return (
        <>
            <h2 style={{ padding: '0' }} className='title'>Личный кабинет</h2>
            <div className='lk_row'>
                <div className="lk_box">
                    <h2>{username}</h2>
                    <p>Баланс: {balance}</p>
                    <p>Баллы: {coins}</p>
                    <div className="promo-section">
                        <input
                            className="promo-input"
                            type="text"
                            placeholder="Введите промокод"
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                        />
                        <button className="promo-button" onClick={applyPromoCode}>Применить промокод</button>
                    </div>
                    {promoMessage && <p className="promo-message">{promoMessage}</p>}

                    {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Сообщение об ошибке */}
                </div>

                <WheelOfFortune userId={localStorage.getItem('userid')} />
            </div>
            {/* <div className='stats'>
                <h2 style={{ padding: '0' }} className='title'>Ваша Статистика</h2>
                <p>Проведенное время в клубе:</p>
                <p>{loginTime ? `${loginTime} часов` : 'Загрузка...'}</p> Анимация времени входа
            </div> */}
            <FreeNight userId={localStorage.getItem('userid')} />

        </>
    );
}
