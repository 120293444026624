import React from 'react';
import { FaTelegramPlane, FaVk, FaPhone } from 'react-icons/fa'; // Импорт иконок

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                {/* Ссылки на соцсети */}
                <div className="footer-links">
                    <a href="https://t.me/teamclubkrd_bot" className="icon-link" target="_blank" rel="noopener noreferrer">
                        <FaTelegramPlane className="icon" />
                    </a>
                    <a href="https://vk.com/teamclubkrd" className="icon-link" target="_blank" rel="noopener noreferrer">
                        <FaVk className="icon" />
                    </a>
                    <a href="tel:+7 (988) 387-67-87" className="icon-link">
                        <FaPhone className="icon" /> +7 (988) 387-67-87
                    </a>
                </div>
                {/* Ссылка на разработчика через Telegram */}
                <div className="footer-developer">
                    <svg width="30" height="20" viewBox="0 0 90 71" fill="" xmlns="http://www.w3.org/2000/svg"><rect x="6" y="10" width="35" height="52" fill="#000000"></rect><path d="M0 0V70.338H89.521V0H0ZM19.184 53.481L12.79 47.085L19.184 40.691L25.578 34.2971C25.578 34.2971 21.681 30.4 19.184 27.903C16.687 25.406 12.79 21.509 12.79 21.509L15.987 18.3115L19.184 15.114L28.7755 24.7055L38.367 34.2971L28.7755 43.889L19.184 53.481Z" fill="white"></path><rect className="dode" x="45" y="44" width="29" height="8" fill="#000000"></rect></svg>
                    <span>Твой разработчик</span>
                    <a href="https://t.me/garsom02" className="icon-link" target="_blank" rel="noopener noreferrer">
                        <FaTelegramPlane className="icon" />
                    </a>
                </div>
            </div>

            
        </footer>
    );
};

export default Footer;
