import React from "react"

export function Login({ onLogin }) {
    const [username, setusername] = React.useState('')
    const [password, setPassword] = React.useState('')


    function handleusernameInput(e) {
        setusername(e.target.value)
    }
    function handlePasswordInput(e) {
        setPassword(e.target.value)
    }

    function handleSubmit(e) {
        e.preventDefault()
        onLogin(username, password)
    }

    return (
        <div className="login">

            <form className="login__form" onSubmit={handleSubmit}>
                <h1 className="login__title">Вход</h1>
                <input
                    className="login__input"
                    placeholder="username"
                    id="username"
                    name="username"
                    type="username"
                    value={username}
                    onChange={handleusernameInput}
                    required
                />
                <input
                    className="login__input"
                    placeholder="Пароль"
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={handlePasswordInput}
                    required
                />
                <button
                    type="submit"
                    className="login__submit">
                    Войти
                </button>
            </form>
        </div>

    )
}