import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaHome, FaGamepad, FaCalendarAlt, FaUser, FaCamera } from "react-icons/fa"; // Иконка для галереи

const BottomNav = () => {
    const location = useLocation(); // Для отслеживания текущего пути

    const [active, setActive] = useState(location.pathname); // Устанавливаем активный путь на основе URL

    return (
        <div className="bottom-nav">
            <Link
                to="/"
                className={`nav-item ${active === "/" ? "active" : ""}`}
                onClick={() => setActive("/")}
            >
                <FaHome className="icon" />
                <div className="text">Главная</div>
            </Link>
            <Link
                to="/game"
                className={`nav-item ${active === "/game" ? "active" : ""}`}
                onClick={() => setActive("/game")}
            >
                <FaGamepad className="icon" />
                <div className="text">Игры</div>
            </Link>
            <Link
                to="/booking"
                className={`nav-item ${active === "/booking" ? "active" : ""}`}
                onClick={() => setActive("/booking")}
            >
                <FaCalendarAlt className="icon" />
                <div className="text">Бронь</div>
            </Link>
            <Link
                to="/lk"
                className={`nav-item ${active === "/lk" ? "active" : ""}`}
                onClick={() => setActive("/lk")}
            >
                <FaUser className="icon" />
                <div className="text">Кабинет</div>
            </Link>
            <Link
                to="/photo"
                className={`nav-item ${active === "/photo" ? "active" : ""}`}
                onClick={() => setActive("/photo")}
            >
                <FaCamera className="icon" />
                <div className="text">Галерея</div>
            </Link>
        </div>
    );
};

export default BottomNav;
