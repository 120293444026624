import { Link } from 'react-router-dom'
import ps from '../images/ps_gif.gif'
import a from '../images/logo.png'
import s from '../images/pc/s.png'
import k from '../images/pc/k.png'
import kp from '../images/pc/k+.png'
import vip from '../images/pc/vip.png'
import nigth from '../images/night.png'
import energy from '../images/energy.png'
import pocket from '../images/pocket.png'
import five from '../images/five.png'
export default function About() {
    const MainContent = () => {
        return (
            <section className="main-content">
                <div className="main-text">
                    <h1>Компьютерный клуб</h1>
                    <p>Рашпилевская, 22</p>
                    <Link to="/booking">
                        <button className="btn-primary">Забронировать</button>
                    </Link>
                </div>
                <div className="image-wrapper">
                    <img src={a} className="about_img" alt="Gaming Setup" />
                </div>
            </section>
        );
    };

    const CardSection = () => {
        const cards = [
            { title: "Стандарт", description: "17 ПК", photo: s, path: "/standart", className: 'card-yellow' },
            { title: "Комфорт", description: "10 ПК", photo: k, path: "/komfort", className: 'card-blue' },
            { title: "Комфорт +", description: "8 ПК", photo: kp, path: "/komfortplus", className: 'card-pink' },
            { title: "Vip", description: "10 ПК", photo: vip, path: "/vip", className: 'card-green' },
            { title: "PS", description: "3 Консоли", photo: ps, path: "/ps", className: 'card-pink' },
        ];

        return (
            <section className="card-section">
                {cards.map((card, index) => (
                    <Link to={card.path} key={index} className="card-link">
                        <div className={`card ${card.className}`}>
                            <img src={card.photo} alt={card.title} className="card-image" />
                            <h3>{card.title}</h3>
                            <p>{card.description}</p>
                            <button className="btn-secondary">Цены и конфигурации</button>
                        </div>
                    </Link>
                ))}

            </section>
        );
    };
    const PromoSection = () => {
        const promo = [
            { title: "5 ночь в подарок", description: "Отсиди 4 ночи в течении месяца", photo: nigth, className: 'card-pink' },
            { title: "5% кешбек", description: "Начилсляем по итогам месяца", photo: five, className: 'card-blue' },
            { title: "Пакеты часов", description: "Скидка до 100% за час", photo: pocket, className: 'card-pink' },
            { title: "Энергетик в подарок", description: "Каждую 3-ю ночь", photo: energy, className: 'card-blue' },
            // { title: "50% скидка", description: "Скидка в день рождение", photo: ps, className: 'card-pink' },
        ];

        return (
            <>
                <h1 style={{ margin: 'auto' }}>Акции</h1>
                <section className="card-section">
                    {promo.map((promo, index) => (
                        <Link to={promo.path} key={index} className="card-link">
                            <div className={`card ${promo.className}`}>
                                <img src={promo.photo} alt={promo.title} className="card-image" />
                                <h2 className='text_btn'>{promo.title}</h2>
                                <p className='text_btn'>{promo.description}</p>
                            </div>
                        </Link>
                    ))}
                </section>
            </>
        );
    };
    return (
        <>
            <MainContent />
            <CardSection />
            <PromoSection />
        </>
    );
}
