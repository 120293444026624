import React from 'react';

export default function Zal({ pcData, consoleData, onEditProfile }) {
    return (
        <>
            <div className="zal-categories">
                <div className="zal-category">
                    <span className="zal-category-color standard"></span>
                    <p>Стандарт 1-17</p>
                </div>
                <div className="zal-category">
                    <span className="zal-category-color comfort"></span>
                    <p>Комфорт 18-27</p>
                </div>
                <div className="zal-category">
                    <span className="zal-category-color comfort-plus"></span>
                    <p>Комфорт + 28-35</p>
                </div>
                <div className="zal-category">
                    <span className="zal-category-color vip"></span>
                    <p>VIP 36-45</p>
                </div>
            </div>
            <div className="zal">
                <div className='standart_vip_comfort'>
                    <div className="zal__std-vip">
                        <ul className="zal__standart">
                            <ul className="standart__1-3">
                                {pcData.slice(0, 3).map((pc, index) => (
                                    <li key={index} className="zal__li">
                                        <button onClick={onEditProfile} className="zal__li_text">{pc.id}</button>
                                        <div className='zal__li_time_active'>{pc.availableTime}</div>
                                    </li>
                                ))}
                            </ul>
                            <div className='qwe'>
                                <ul className="standart__4-12">
                                    {pcData.slice(3, 12).map((pc, index) => (
                                        <li key={index + 3} className="zal__li">
                                            <button onClick={onEditProfile} className="zal__li_text">{pc.id}</button>
                                            <div className='zal__li_time_active'>{pc.availableTime}</div>
                                        </li>
                                    ))}
                                </ul>
                                <ul className="standart__13-17">
                                    {pcData.slice(12, 17).map((pc, index) => (
                                        <li key={index + 12} className="zal__li">
                                            <button onClick={onEditProfile} className="zal__li_text">{pc.id}</button>
                                            <div className='zal__li_time_active'>{pc.availableTime}</div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </ul>
                        <ul className="zal__vip">
                            <ul className="zal__vip_36-40">
                                {pcData.slice(35, 40).map((pc, index) => (
                                    <li key={index + 35} className="zal__li">
                                        <button onClick={onEditProfile} className="zal__li_text">{pc.id}</button>
                                        <div className="zal__li_time_active">{pc.availableTime}</div>
                                    </li>
                                ))}
                            </ul>
                            <ul className="zal__vip_41-45">
                                {pcData.slice(40, 45).map((pc, index) => (
                                    <li key={index + 40} className="zal__li">
                                        <button onClick={onEditProfile} className="zal__li_text">{pc.id}</button>
                                        <div className="zal__li_time_active">{pc.availableTime}</div>
                                    </li>
                                ))}
                            </ul>
                        </ul>
                    </div>
                    <ul className="zal__comfort">
                        <ul className="zal__comfort_18-27">
                            {pcData.slice(17, 27).map((pc, index) => (
                                <li key={index + 17} className="zal__li">
                                    <button onClick={onEditProfile} className="zal__li_text">{pc.id}</button>
                                    <div className='zal__li_time_active'>{pc.availableTime}</div>
                                </li>
                            ))}
                        </ul>

                    </ul>
                </div>
                <div className="zal__reception-komfortplus-ps">
                    <div className="zal__reception">
                        <a className='text_btn' target='blank'>Вход</a>
                    </div>
                    <ul className="zal__comfort-plus">
                        <div className="zal__comfortplus_28-35">
                            <ul className="zal__comfortplus_32-35">
                                {pcData.slice(31, 35).map((pc, index) => (
                                    <li key={index + 31} className="zal__li">
                                        <button onClick={onEditProfile} className="zal__li_text">{pc.id}</button>
                                        <div className='zal__li_time_active'>{pc.availableTime}</div>
                                    </li>
                                ))}
                            </ul>
                            <ul className="zal__comfortplus_28-31">
                                {pcData.slice(27, 31).map((pc, index) => (
                                    <li key={index + 27} className="zal__li">
                                        <button onClick={onEditProfile} className="zal__li_text">{pc.id}</button>
                                        <div className='zal__li_time_active'>{pc.availableTime}</div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </ul>
                    <ul className="zal__ps">
                        {['PS5_1', 'PS4_2', 'PS4_3'].map((consoleKey) => {
                            const console = consoleData[consoleKey];
                            return (
                                <li key={consoleKey} className="zal__ps_li">
                                    <button onClick={onEditProfile} className="zal__ps_li_text">
                                        {console?.id || consoleKey} {/* Проверка существования объекта консоли */}
                                    </button>
                                    <div className='zal__ps_li_text'>
                                        {console?.availableTime || 'Свободен'} {/* Проверка времени или "Свободен" */}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </>
    );
}
