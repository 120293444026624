import { CurrentUserContext } from "../contexts/CurrentUserContext"
import React from "react"
import { PopupWithForm } from "./PopupWithForm"

export function EditProfilePopup(props) {
    const [time, setTime] = React.useState(' ')
    const [pcNumber, setPcNumber] = React.useState(' ')
    const [pcDuration, setPcDuration] = React.useState(' ')
    const [userNumber, setuserNumber] = React.useState(' ')
    const currentUser = React.useContext(CurrentUserContext)

    React.useEffect(() => {
        setTime(currentUser.time);
        setPcNumber(currentUser.number);
        setPcDuration(currentUser.duration);
        setuserNumber(currentUser.phone)
    }, [currentUser, props.isOpen])

    function handleSubmit(e) {
        e.preventDefault();
        props.onUpdateUser({
            time,
            pcNumber,
            pcDuration,
            userNumber
        })
    }
    return (
        <PopupWithForm
            onSubmit={handleSubmit}
            name='booking'
            isOpen={props.isOpen}
            onClose={props.onClose}
            buttonText={'Забронировать'}
            onUpdateUser={props.onUpdateUser}
            title='Бронирование'
        >
            <input
                value={time || ' '}
                onChange={(e) => setTime(e.target.value)}
                placeholder="Укажите время"
                id="input-name"
                type="time"
                className="popup__input popup__input-name" />

            <span className="popup__input-error" id="error-input-name"></span>
            <input
                value={pcNumber || ' '}
                onChange={(e) => setPcNumber(e.target.value)}
                name="PC"
                type="number"
                step="1"
                min="1"
                max="45"
                id="input-pc"
                className="popup__input"
                required
                placeholder="Номер ПК" />
            <span className="popup__input-error" id="error-input-job"></span>
            <input
                value={pcDuration || ' '}
                onChange={(e) => setPcDuration(e.target.value)}
                name="duration"
                type="number"
                step="1"
                min="1"
                max="24"
                id="input-duration"
                className="popup__input"
                required
                placeholder="На сколько часов?" />

            <span className="popup__input-error" id="error-input-job"></span>
            <input
                value={userNumber || ' '}
                onChange={(e) => setuserNumber(e.target.value)}
                name="phone"
                type="number"

                id="input-phone"
                className="popup__input"
                placeholder="+7 Укажите номер телефона"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />

            <span className="popup__input-error" id="error-input-job"></span>
        </PopupWithForm>
    )
}