import React, { useEffect, useState } from 'react';

export default function Game() {
    const [games, setGames] = useState([]);

    useEffect(() => {
        fetch('/api/games')
            .then(response => response.json())
            .then(data => setGames(data))
            .catch(error => console.error('Ошибка при загрузке игр:', error));
    }, []);

    return (
        <section className="tournament">
            <h2 className="title">Библиотека</h2>
            <div className="tournament__columns">
                {games.map(game => (
                    <div className='tournament_div'>
                        <img
                            key={game.id}
                            className="tournament__photo"
                            src={game.imagePath} // Указываем полный путь

                            alt={game.title}
                        />
                        <p>{game.title}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}
