import headerLogo from '../images/logo.png'
import { Link } from 'react-router-dom'

export function Header({ title, route, onClick, balance }) {

    return (
        <>
            <header className="header">
                <Link to='/'>
                    <img className="header__logo" src={headerLogo} alt='' />
                </Link>
                <div className='header__bar_tab'>
                    <Link className='header__bar_link_zal' to='/game'>Игры</Link>

                    <Link className='header__bar_link_zal' to='/booking'>Бронирование</Link>

                    {/* <nav>
                        <button className='header__bar_link_zal' onClick={() => setOpen(!isOpen)}>Конфигурация</button>
                        <div 
                        ref={ref}
                         className={`header__bar_menu ${isOpen ? 'header__bar_menu_active' : ''}`}>
                            <NavLink className='header__bar_link_std' to='/standart'>Стандарт</NavLink>
                            <NavLink className='header__bar_link_kom' to='/komfort'>Комфорт</NavLink>
                            <NavLink className='header__bar_link_komplus' to='/komfortplus'>Комфорт +</NavLink>
                            <NavLink className='header__bar_link_vip' to='/vip'>VIP</NavLink>
                        </div>
                    </nav> */}
                    <Link className='header__bar_link_zal' to='/Lk'>Кабинет</Link>
                    <Link className='header__bar_link_zal' to='/photo'>Галерея</Link>
                </div>
                <div
                    className={`header__balance ${balance === null ? 'header__balance_unactive' : ''}`}>
                    Баланс: {balance !== null ? balance : 'Авторизуйтесь'}
                </div>
                <div className='header__enter'>
                    <Link
                        to='/sign-in'
                        className='header__login-exit'
                        onClick={onClick}
                    >
                        {title}
                    </Link>
                </div>
            </header>
        </>
    )
}