import ImageGallery from "react-image-gallery";
import React from "react";
import slide_1 from '../images/live_photo/HeadPhone.png'
import slide_2 from '../images/live_photo/keyboard.jpg'
import slide_3 from '../images/live_photo/23-27.jpg'
import slide_4 from '../images/live_photo/dualsense.jpg'
import slide_5 from '../images/live_photo/Mv3EkT-XPBE.jpg'
import slide_6 from '../images/live_photo/2.jpg'
import slide_7 from '../images/live_photo/7.jpg'
import slide_8 from '../images/live_photo/8.jpg'
import slide_9 from '../images/live_photo/9.jpg'
import slide_10 from '../images/live_photo/10.jpeg'
import slide_11 from '../images/live_photo/11.jpg'
import slide_12 from '../images/live_photo/12.jpg'
import slide_13 from '../images/live_photo/13.jpg'
import slide_14 from '../images/live_photo/ezrcz44HOh4.jpg'

const images = [
    {
        original: slide_1,
        thumbnail: slide_1,
    },
    {
        original: slide_2,
        thumbnail: slide_2,
    },
    {
        original: slide_3,
        thumbnail: slide_3,
    },
    {
        original: slide_4,
        thumbnail: slide_4,
    },
    {
        original: slide_5,
        thumbnail: slide_5,
    },
    {
        original: slide_6,
        thumbnail: slide_6,
    },
    {
        original: slide_7,
        thumbnail: slide_7,
    },
    {
        original: slide_8,
        thumbnail: slide_8,
    },
    {
        original: slide_9,
        thumbnail: slide_9,
    },
    {
        original: slide_10,
        thumbnail: slide_10,
    },
    {
        original: slide_11,
        thumbnail: slide_11,
    },
    {
        original: slide_12,
        thumbnail: slide_12,
    },
    {
        original: slide_13,
        thumbnail: slide_13,
    },
    {
        original: slide_14,
        thumbnail: slide_14,
    },
];

export default class MyGallery extends React.Component {
    render() {

        return <div className="s"> <ImageGallery items={images} />;
        </div>
    }
}
