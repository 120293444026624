import React, { useState } from 'react';

export default function AdminPanel() {
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('image', image);

        await fetch('/api/games', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                alert('Игра успешно добавлена');
                setTitle('');
                setImage(null);
            })
            .catch(error => console.error('Ошибка при добавлении игры:', error));
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                placeholder="Название игры"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
            />
            <input
                type="file"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
                required
            />
            <button type="submit">Добавить игру</button>
        </form>
    );
}
