import Config from "../Config"

export default function Standart() {
    const OneHourse = 100;
    const threeHourse = 250;
    const foureHourse = 300;
    const twelveHourse = 700;
    const night = 400;

    return (
        <>
            <Config
                zal='Стандарт'
                proc='i5 8500KF'
                videocard='GTX 1060'
                ssd='2 TB'
                oper='16 GB'
                monik='Iyama 24.5 144 Ghz'
                keyboard='Zet Gaming'
                mouse='Logitech G 102'
                headPhone='HyperX cloud X'
                OneHourse= {OneHourse}
                threeHourse={threeHourse}
                foureHourse={foureHourse}
                twelveHourse={twelveHourse}
                night={night}
                threeOne={Math.floor(threeHourse / 3)}
                foureOne={Math.floor(foureHourse / 4)}
                twelveOne={Math.floor(twelveHourse / 12)}
                nightOne={Math.floor(night / 11)}
            />
        </>
    )
}
